import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import LayoutWrapper from "./components/LayoutWrapper";
import Dev from "./pages/Dev";
import PrivacyPolicy from "./pages/PrivacyPolicy"
import Blog from "./pages/Blog"
import Demo from "./pages/Demo"
import Pricing from "./pages/Pricing"
import AboutUs from "./pages/AboutUs"
import Careers from "./pages/Careers"
import Blog_1 from "./pages/blogs/Blog_1"
import Blog_2 from "./pages/blogs/Blog_2"
import Blog_3 from "./pages/blogs/Blog_3"

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    // <React.StrictMode>
    <Router>
        <Routes>
            {/*<Route path="/signup" element={<LayoutWrapper component={Signup}/>}/>*/}
            {/*<Route path="/login" element={<LayoutWrapper component={Login}/>}/>*/}

            <Route path="/dev" element={<LayoutWrapper component={Dev}/>}/>
            <Route path="/blog" element={<LayoutWrapper component={Blog}/>}/>
            <Route path="/blog/blog-1" element={<LayoutWrapper component={Blog_1}/>}/>
            <Route path="/blog/blog-2" element={<LayoutWrapper component={Blog_2}/>}/>
            <Route path="/blog/blog-3" element={<LayoutWrapper component={Blog_3}/>}/>


            <Route path="/demo" element={<LayoutWrapper component={Demo}/>}/>
            {/*<Route path="/pricing" element={<LayoutWrapper component={Pricing}/>}/>*/}
            <Route path="/about-us" element={<LayoutWrapper component={AboutUs}/>}/>
            <Route path="/careers" element={<LayoutWrapper component={Careers}/>}/>
            <Route path="/privacy-policy" element={<LayoutWrapper component={PrivacyPolicy}/>}/>
            {/*<Route path="/profile" element={<LayoutWrapper component={Profile}/>}/>*/}
            {/*<Route path="/alumni" element={<LayoutWrapper component={Alumni}/>}/>*/}
            <Route path="/" element={<LayoutWrapper component={App}/>}/>
            <Route path="/*" element={<LayoutWrapper component={App}/>}/>

        </Routes>
    </Router>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
