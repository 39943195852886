import React from 'react';
import {Layout, Typography} from 'antd';
import ReactMarkdown from 'react-markdown';
import {useIsMobile} from "../../components/LayoutWrapper"
import SEO from "../../components/SEO"

const {Title} = Typography;

const {Content, Footer} = Layout;

const BlogTemplate: React.FC<any> = ({markdown, id, titleSeo = "Marketing Mix Modeling in a Cookieless World"}) => {
    const isMobile = useIsMobile()


    return (
        <>
            <SEO
                title={titleSeo}
            />
            <Content className="content"
                     style={{
                         display: 'flex',
                         flexDirection: 'column',
                         // justifyContent: 'center',
                         alignItems: 'center',
                         paddingTop: ' 5vh',
                         paddingBottom: '10vh',
                         backgroundColor: 'white'
                         // height: '60vh'
                     }}>
                <div
                    style={{
                        width: isMobile ? '80vw' : '60vw',
                        textAlign: 'justify'
                    }}
                >
                    <img src={require(`./blog_${id}.png`)}
                         style={{
                             width: isMobile ? ' 100%' : '60%',

                         }}
                    />
                    <ReactMarkdown>
                        {markdown}
                    </ReactMarkdown>
                </div>
            </Content>
        </>
    );
};

export default BlogTemplate