import React, {useEffect, useState} from 'react';
import {Typography} from 'antd';
import {colorPrimary} from "../global";
import {useIsMobile} from "./LayoutWrapper"

const {Title} = Typography;

interface AnimatedTitleProps {
    quote: string;
    textArray: string[];
}
const AnimatedTitle: React.FC<AnimatedTitleProps> = (
    { quote, textArray }
) => {
    const isMobile = useIsMobile()
    const [textIndex, setTextIndex] = useState(0);
    const [displayedText, setDisplayedText] = useState('');
    // const textArray = ['marketing', 'artificial intelligence'];

    useEffect(() => {
        let currentText = textArray[textIndex];
        let currentCharIndex = 0;
        let isDeleting = false;
        const typingSpeed = 120;
        const fullWordPause = 200;
        const intervalId = setInterval(() => {
            if (!isDeleting) {
                setDisplayedText(currentText.slice(0, currentCharIndex + 1));
                currentCharIndex++;
                if (currentCharIndex === currentText.length) {
                    isDeleting = true;
                    setTimeout(() => {
                    }, fullWordPause);
                }
            } else {
                setDisplayedText(currentText.slice(0, currentCharIndex - 1));
                currentCharIndex--;
                if (currentCharIndex === 0) {
                    isDeleting = false;
                    clearInterval(intervalId);
                    setTimeout(() => {
                        setTextIndex((prev) => (prev + 1) % textArray.length);
                    }, fullWordPause);
                }
            }
        }, typingSpeed);

        return () => clearInterval(intervalId);
    }, [textIndex]);

    return (
        <Title
            style={{
                marginTop: '100px',
                // width: '40%',
                textAlign: 'center',

            }}
        >
            {quote} <br/>
            <span className="highlight"
                  style={{
                      color: colorPrimary,
                      fontSize: isMobile? undefined:'70px',
                  }}
            >{displayedText || '\u00A0'}</span>
        </Title>
    );
};

export default AnimatedTitle;