import React from 'react';
import {Helmet} from 'react-helmet';


const SEO: React.FC<any> = ({title, description = ''}) => (
    <Helmet>
        <title>{title}</title>
    </Helmet>
);

export default SEO;
