import React from 'react';
import {Typography} from "antd"
import {blockGap, colorPrimary} from "../global"
import {useIsMobile} from "../components/LayoutWrapper"
import BottomLeftBackround from "../background/square.svg"
import TopRightBackground from "../background/square2.svg"

const {Title} = Typography
export const CustomP: React.FC<any> = (
    {
        children,
        style
    }
) => {
    const isMobile = useIsMobile()
    return (
        <p
            style={{
                fontSize: isMobile ? '18px' : '22px',
                color: 'grey',
                textAlign: isMobile ? 'center' : 'left',
                paddingLeft: '5vw',
                paddingRight: '5vw',
                ...style
            }}>
            {children}
        </p>
    )
}

export const CustomPWhite: React.FC<any> = (
    {
        children,
        style
    }
) => {
    return (
        <CustomP
            style={{
                color: 'white',
                ...style
            }}>
            {children}
        </CustomP>
    )
}

export const CustomHeader2: React.FC<any> = (
    {
        children,
        style,
        icon = null
    }
) => {
    const isMobile = useIsMobile()
    return (
        <p
            style={{
                fontSize: '24px',
                fontWeight: 'bold',
                color: 'black',
                textAlign: isMobile ? 'center' : 'left',
                ...style
            }}>
            {icon && <span style={{marginRight: '8px'}}>{icon}</span>}
            {children}
        </p>
    )
}

export const CustomP2: React.FC<any> = (
    {
        children,
        style
    }
) => {
    const isMobile = useIsMobile()
    return (
        <p
            style={{
                fontSize: isMobile ? '18px' : '20px',
                paddingLeft: isMobile ? '10px' : null,
                paddingRight: isMobile ? '10px' : null,

                color: 'grey',
                textAlign: isMobile ? 'center' : 'left',

                ...style
            }}>
            {children}
            <br/><br/>
        </p>
    )
}

export const CustomP2White: React.FC<any> = (
    {
        children,
        style
    }
) => {
    return (
        <CustomP2
            style={{
                color: '#efefefff',
                ...style
            }}>
            {children}
        </CustomP2>
    )
}

export const CustomHeader: React.FC<any> = (
    {
        children,
        style
    }
) => {
    const isMobile = useIsMobile()

    return (
        <Title

            level={2}
            style={{
                // fontSize: '30px',
                fontWeight: 'bold',
                color: 'black',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: isMobile ? 'center' : 'left',
                paddingLeft: isMobile ? '10px' : undefined,
                paddingRight: isMobile ? '10px' : undefined,
                ...style
            }}>
            {children}
        </Title>
    )
}

export function getHeightGap(height: number) {
    return {
        height: `${height}vh`,
        heightGap: `${height + blockGap}vh`
    }

}


export const BoxBackground: React.FC<any> = ({children, style}) => {
    const isMobile = useIsMobile();

    return (
        <div
            style={{
                height: isMobile ? '83vh' : '83vh',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                backgroundImage: `url(${BottomLeftBackround}), url(${TopRightBackground})`,
                backgroundPosition: 'calc(100% - 90vw) 10vh, 85vw -10vh',
                backgroundRepeat: 'no-repeat, no-repeat',
                backgroundSize: '50vh, 45vh',
                ...style
            }}
        >
            {children}
        </div>
    )

}

export const Section: React.FC<any> = (
    {
        children,
        justifyContent = 'left',
        backgroundColor = colorPrimary,
    }
) => {
    const isMobile = useIsMobile()
    const justifyContent_ = isMobile ? 'center' : justifyContent
    return (
        <div style={{
            // height: heightGap,
            marginLeft: isMobile ? '0vh' : '3vh',
            marginRight: isMobile ? '0vh' : '3vh',
            marginBottom: isMobile ? '10vh' : '20vh',
            // marginTop: '30vh',
            display: 'flex',
            justifyContent: justifyContent_


        }}>
            <div
                style={{
                    backgroundColor: backgroundColor,
                    borderRadius: '10px',
                    display: 'flex',
                    width: isMobile ? '90%' : '80%',
                    flexDirection: 'column',
                    paddingBottom: '5vh',
                    paddingTop: '2vh'

                }}
            >
                {children}
            </div>
        </div>
    )

}



export const SectionGrey: React.FC<any> = (
    {
        children,

    }
) => {
    return (
        <Section
            justifyContent = 'end'
        backgroundColor = 'whitesmoke'

        >
            {children}
        </Section>
    )
}