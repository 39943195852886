import React, {useState} from 'react';
import {ButtonDemo} from "./components/ButtonDemo"
import type {StatisticProps} from 'antd';
import {Col, Row, Statistic} from "antd"

import MmmIcon from './icons/ai_icon.png'
import CookielessIcon from './icons/cookieless.png'

import {
    BarChartOutlined,
    CloudOutlined,
    DatabaseOutlined,
    DollarOutlined,
    FundOutlined,
    LineChartOutlined,
    RiseOutlined,
    SafetyOutlined,
    SolutionOutlined
} from '@ant-design/icons';
import {useIsMobile} from "./components/LayoutWrapper"
import {analytics} from "./utils/firebase"
import {logEvent} from 'firebase/analytics';
import AnimatedTitle from "./components/AnimatedTitle"
import CountUp from 'react-countup';
import AnimatedPrettyPlot from "./components/AnimatedPrettyPlot"
import {
    BoxBackground,
    CustomHeader,
    CustomHeader2,
    CustomP,
    CustomP2,
    CustomP2White,
    CustomPWhite,
    getHeightGap,
    Section
} from "./components/Headers"
import {useNavigateHome} from "./utils/navigate_home"
import {useInView} from "react-intersection-observer"


const ChartPlot: React.FC = () => {
    const isMobile = useIsMobile()
    return (
        <div style={{margin: '5vh'}}>
            <AnimatedPrettyPlot/>
        </div>
    )
}


const useFormatter: StatisticProps['formatter'] = (value, color) => {
    const {ref, inView} = useInView({
        triggerOnce: false
    })

    return (
        <div ref={ref}>
            {inView ? (
                <CountUp end={value as number} separator="," style={{color: '#B8B8B8'}}/>
            ) : (
                value
            )}
        </div>
    )
}

const HomepageKpis: React.FC = () => {
    const isMobile = useIsMobile()
    let kpis = [
        {
            title: 'Avg sales',
            value: 14,
            suffix: '%',
            prefix: '+'
        },
        {
            title: 'Avg savings',
            value: 16,
            suffix: '%',
            prefix: '+'
        },
        {
            title: 'Budget optimized',
            value: 7.3,
            suffix: 'M€',
            prefix: '+'
        },
        {
            title: 'Avg ROI',
            value: 34,
            suffix: '%',
            prefix: '+'
        }
    ]
    if (isMobile) {
        kpis = kpis.slice(0, 2)
    }
    const color = '#B8B8B8'

    return (
        <Row
            // gutter={400}
            style={{
                // marginTop: '16vh',
                display: 'flex',
                alignItems: 'end',
                height:'100%',

                width: isMobile ? '100%' : '70%'

            }}
        >
            {kpis.map((val, idx) => (
                <>
                    <Col
                        span={isMobile ? 11 : 5}
                        offset={1}
                        style={{
                            marginBottom: isMobile ? '5vh' : '10vh',
                            display: 'flex',
                            justifyContent: 'center'
                            // alignContent: 'center'
                        }}

                    >
                        <Statistic
                            title={<div style={{color}}>{val.title}</div>}
                            value={val.value}
                            formatter={useFormatter}
                            suffix={<div style={{color}}>{val.suffix}</div>}
                            prefix={<div style={{color}}>{val.prefix}</div>}
                        />
                    </Col>
                </>
            ))}


        </Row>
    )
}


const Homepage: React.FC = () => {
    const isMobile = useIsMobile();

    return (
        <BoxBackground>
            <AnimatedTitle
                quote={"Unleash the power of"}
                textArray={['marketing', 'artificial intelligence']}
            />

            <CustomP
                style={{
                    textAlign: 'center'
                }}

            >
                Optimize your Media Investment with AI-enhanced Marketing Mix Modeling to increase your sales
                <br/><br/>
            </CustomP>

            <ButtonDemo
                style={{
                    fontSize: '20px',
                    width: '250px',
                    height: '70px'
                }}
            />
            <HomepageKpis/>
        </BoxBackground>
    );
};

const SectionChart: React.FC = () => {
    const isMobile = useIsMobile()
    const CustomHeaderValue: React.FC = () => {
        return (
            <>
                <CustomHeader
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    Marketing optimization platform
                </CustomHeader>
                <CustomPWhite
                    style={{
                        paddingLeft: isMobile ? '10vw' : null,
                        paddingRight: isMobile ? '10vw' : null
                    }}
                >
                    Laia uses Marketing Mix Modeling to boost your sales, optimize your budget spend and maximize your
                    ROI
                </CustomPWhite>
            </>
        )
    }
    return (
        <Section>
            {isMobile ?
                <>
                    <CustomHeaderValue/>
                    <ChartPlot/>
                </>
                :
                <Row>
                    <Col span={8} offset={1}>
                        <div>
                            <p>
                                <br/><br/>
                            </p>
                            <CustomHeaderValue/>
                        </div>
                    </Col>
                    <Col span={12} offset={1}>
                        <ChartPlot/>
                    </Col>
                </Row>
            }
        </Section>
    )
}

const SectionBug: React.FC = () => {
    const isMobile = useIsMobile()

    const MarketingData: React.FC = () => {
        return (
            <>
                <CustomHeader2 icon={<BarChartOutlined/>}>
                    Marketing channels
                </CustomHeader2>
                <CustomP2>
                    Laia analyzes Online and Digital Campaigns, Offline Campaigns, Newsletters and any other Marketing
                    activity you track
                </CustomP2>
            </>
        )
    }

    const RevenueData: React.FC = () => {
        return (
            <>
                <CustomHeader2 icon={<LineChartOutlined/>}>
                    Revenue data
                </CustomHeader2>
                <CustomP2>
                    Include your revenue data and Laia will build the causal relationships with your media channels
                </CustomP2>
            </>
        )
    }

    const ExternalFactors: React.FC = () => {
        return (
            <>
                <CustomHeader2 icon={<CloudOutlined/>}>
                    Contextual factors
                </CustomHeader2>
                <CustomP2>
                    Any external factor that can be measured and tracked is added to the model: Holidays, Weather,
                    Geopolitical events, Competitor Data, etc.
                </CustomP2>
            </>
        )
    }

    const ROI: React.FC = () => {
        return (
            <>
                <CustomHeader2 icon={<RiseOutlined/>}>
                    ROI per channel
                </CustomHeader2>
                <CustomP2>
                    Laia calculates the true ROI per channel, understanding cross-media effects holistically.
                </CustomP2>
            </>
        )
    }

    const BudgetOptimizer: React.FC = () => {
        return (
            <>
                <CustomHeader2 icon={<FundOutlined/>}>
                    Budget optimizer
                </CustomHeader2>
                <CustomP2>
                    Laia optimizes your spend per channel, increasing your total revenue and your ROI attributed to your
                    marketing effort
                </CustomP2>
            </>
        )
    }

    const ActionPlan: React.FC = () => {
        return (
            <>
                <CustomHeader2 icon={<SolutionOutlined/>}>
                    Multiple scenarios simulation
                </CustomHeader2>
                <CustomP2>
                    Laia builds for you multiple budget scenarios so you can choose how much you want to
                    increase/decrease your Marketing Budget and get the recommendations to get the Mix of Channels that
                    maximizes your total Revenue.
                </CustomP2>
            </>
        )
    }

    return (
        <Section justifyContent="end" backgroundColor="whitesmoke">
            <CustomHeader
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                Measure and optimize
            </CustomHeader>

            {isMobile ?
                <>
                    <MarketingData/>
                    <RevenueData/>
                    <ExternalFactors/>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%'
                        }}
                    >
                        <img
                            style={{width: '90%'}}
                            src={MmmIcon}/>
                    </div>
                    <ROI/>
                    <BudgetOptimizer/>
                    <ActionPlan/>
                </>
                :
                <Row>
                    <Col span={7} offset={1}>
                        <MarketingData/>
                        <RevenueData/>
                        <ExternalFactors/>
                    </Col>
                    <Col span={7} offset={1}>
                        <img
                            style={{width: '90%'}}
                            src={MmmIcon}/>
                    </Col>
                    <Col span={6} offset={1}>
                        <ROI/>
                        <BudgetOptimizer/>
                        <ActionPlan/>
                    </Col>
                </Row>
            }
        </Section>
    )
}

const SectionCookieLess: React.FC = () => {
    const {height, heightGap} = getHeightGap(50)
    const isMobile = useIsMobile()

    const CookielessFuture: React.FC = () => {
        return (
            <>
                <CustomHeader2 icon={<SafetyOutlined/>}>
                    Cookieless future
                </CustomHeader2>
                <CustomP2White>
                    Privacy regulations and the shift away from third-party cookies are limiting traditional tracking
                    methods, making data-driven approaches like MMM more essential. <br/>
                    Laia offers a future-proof, AI-driven solution that enables businesses to optimize their marketing
                    spend across all channels, even in a cookieless world.
                </CustomP2White>
            </>
        )
    }

    const AffordableAI: React.FC = () => {
        return (
            <>
                <CustomHeader2 icon={<DollarOutlined/>}>
                    Affordable AI
                </CustomHeader2>
                <CustomP2White>
                    Artificial Intelligence is more accessible and affordable than ever, with huge steps being done in
                    the past few years to increase the capabilities and power of AI. <br/>
                    Laia includes a powerful AI Engine to build millions of simulations and choose the best Optimized
                    scenario for you thanks to Machine Learning algorithms. All at a reasonable cost.
                </CustomP2White>
            </>
        )
    }

    const UnifiedData: React.FC = () => {
        return (
            <>
                <CustomHeader2 icon={<DatabaseOutlined/>}>
                    Data accessibility
                </CustomHeader2>
                <CustomP2White>
                    Data is currently more accessible than ever, and it’s easier and cheaper than ever to collect it.
                    This enables MMM methodology to use historical Data to create the Models. <br/>
                    Usually, 1 or 2 years of Data is enough. Some example of Laia input Data includes Online Campaigns
                    in Google Ads, Facebook Ads, Instagram, etc. and Offline Campaigns in TV, OOH, etc.
                </CustomP2White>
            </>
        )
    }

    return (
        <Section>
            <CustomHeader
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center'
                }}
            >
                Why is MMM more relevant than ever?
            </CustomHeader>

            {isMobile ?
                <>
                    <CookielessFuture/>
                    <AffordableAI/>
                    <UnifiedData/>
                </> :
                <Row>
                    <Col span={7} offset={1}>
                        <CookielessFuture/>
                    </Col>
                    <Col span={7} offset={1}>
                        <AffordableAI/>
                    </Col>
                    <Col span={6} offset={1}>
                        <UnifiedData/>
                    </Col>
                </Row>
            }
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%'
                }}
            >
                <img
                    style={{
                        width: isMobile ? '40vw' : '10vw'
                    }}
                    src={CookielessIcon}/>
            </div>
        </Section>
    )
}
const Bottom: React.FC = () => {
    const isMobile = useIsMobile()
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                marginBottom: isMobile ? '10vh' : '20vh'
            }}
        >
            <ButtonDemo
                style={{
                    fontSize: '20px',
                    width: '250px',
                    height: '70px'
                }}/>
        </div>
    )
}

const App: React.FC = () => {
    useNavigateHome()
    logEvent(analytics, 'page_view', {page_title: 'homepage'})

    const repo_name = "ds-laia-homepage"
    const [textDisplay, setTextDisplay] = useState<boolean>(false);
    return (
        <div>
            <Homepage/>
            <SectionChart/>
            <SectionBug/>
            <SectionCookieLess/>
            <Bottom/>
        </div>
    )
};

export default App;

