import React from 'react';
import {useIsMobile} from "../components/LayoutWrapper";
import {BoxBackground, CustomP, Section} from "../components/Headers";
import {Typography} from "antd"

const {Title} = Typography;

const DemoHeader: React.FC = () => {
    const isMobile = useIsMobile()
    return (
        <BoxBackground

            style={{
                height: 'auto',
                marginBottom: isMobile? '5vh': '10vh'
            }}

        >
            <Title
                style={{
                    marginTop: '100px',
                    width: isMobile ? '100%' : '60%',
                    textAlign: 'center'
                }}
            >
                Experience Laia's cutting-edge marketing mix modeling firsthand!
            </Title>
            <CustomP
                style={{
                    marginLeft:isMobile ? undefined:'10vh',
                    marginRight: isMobile ?undefined:'10vh',
                    textAlign: 'center'
                }}
            >
                Dive into our demo to see how we're revolutionizing marketing strategies and driving impactful results.
            </CustomP>
        </BoxBackground>

    );
};
const DemoVideo: React.FC = () => {
    const isMobile = useIsMobile();
    return (

        <Section

        >
            <div
                style={{
                    marginLeft: '5vh'

                }}

            >
                {/*<CustomHeader2>*/}
                {/*    Laia demo*/}
                {/*</CustomHeader2>*/}
                <iframe
                    src="https://www.loom.com/embed/766efb2bf5594f71bc53e969ba2315f6?sid=11da436a-83bc-4ed9-95dc-8ec82c1bcc8d"
                    allowFullScreen
                    style={{
                        width: '90%',
                        height: isMobile ? '200px' : '400px',
                        marginTop: '20px',
                        justifyContent: 'center',
                        alignContent: 'center',
                        display: 'flex'
                    }}
                ></iframe>
            </div>
        </Section>

    )
}


const Demo: React.FC = () => {
    return (
        <div>
            <DemoHeader/>
            <DemoVideo/>

        </div>
    );
};

export default Demo;