import React from 'react';
import {useIsMobile} from "../components/LayoutWrapper";
import {BoxBackground, CustomHeader2, CustomP, CustomP2, Section} from "../components/Headers";
import {Col, Row, Typography} from "antd"
import {colorPrimary} from "../global"
import {Link} from "react-router-dom"

const {Title} = Typography

const BlogHeader: React.FC = () => {
    const isMobile = useIsMobile();
    return (
        <BoxBackground

            style={{
                height: 'auto',
                marginBottom: '10vh'
            }}

        >
            <Title
                style={{
                    marginTop: '100px',
                    // width: '40%',
                    textAlign: 'center'
                }}
            >
                Discover insights with Laia's blogs!
            </Title>
            <CustomP

                style={{
                    marginLeft: isMobile ? undefined : '10vh',
                    marginRight: isMobile ? undefined : '10vh',
                    textAlign: isMobile ? 'center' : 'center'
                }}

            >
                Explore the latest trends in AI and marketing mix modeling with Laia. From innovative strategies to
                impactful applications, see how we're transforming the future of marketing optimization.
            </CustomP>
        </BoxBackground>
    );
};

const BlogPost: React.FC<any> = ({title, date, id}) => {
    const isMobile = useIsMobile()
    // <Section justifyContent='end'
    // >
    let justifyContent = isMobile ? 'center' : 'left'
    let backgroundColor = colorPrimary
    let subtitleColor = 'white'
    if (id % 2 === 0) {
        justifyContent = isMobile ? 'center' : 'end'
        backgroundColor = 'whitesmoke'
        subtitleColor = 'grey'
    }

    return (
        <Section
            justifyContent={justifyContent}
            backgroundColor={backgroundColor}
            // marginTop: '30vh',


        >
            <Link
                to={`/blog/blog-${id}`}

            >
                {isMobile ?
                    <div
                        style={{
                            marginTop: '2vh',
                            marginLeft: isMobile ? '2vh' : undefined,
                            marginRight: isMobile ? '2vh' : undefined
                            // width: '100%',
                            // display: 'flex',
                            // justifyContent: 'center',
                            // alignItems: 'flex-center',
                            // flexDirection: 'column'

                        }}>

                        <img src={require(`./blogs/blog_${id}.png`)}
                             style={{width: '100%'}}
                        />

                        <CustomHeader2>{title}</CustomHeader2>

                        <CustomP2
                            style={{
                                color: subtitleColor
                            }}

                        >{date}</CustomP2>
                    </div> :
                    <Row
                        style={{marginTop: '2vh'}}

                    >
                        <Col
                            span={8}
                            offset={1}
                        >

                            <img src={require(`./blogs/blog_${id}.png`)}
                                 style={{width: '100%'}}
                            />
                        </Col>
                        <Col
                            span={4}
                            offset={1}

                        >
                            <div>

                                <CustomHeader2>{title}</CustomHeader2>
                                <CustomP2
                                    style={{
                                        color: subtitleColor
                                    }}

                                >{date}</CustomP2>
                            </div>
                        </Col>
                    </Row>}
            </Link>
        </Section>
    )
}


const BlogPost2: React.FC<{ title: string, date: string }> = ({title, date}) => (
    <BlogPost
        title={title}
        date={date}
        justifyContent="end"
        backgroundColor="whitesmoke"
    />

)


const Blog: React.FC = () => {
    return (
        <div>
            <BlogHeader/>
            <BlogPost
                id={3}
                title="Are You Ready for a Cookieless World?" date="Oct 21, 2024"/>
            <BlogPost
                id={2}
                title="Laia at Web Summit Lisbon 2024: Pioneering AI-Powered Marketing Optimization"
                date="Oct 14, 2024"/>
            <BlogPost
                id={1}
                title="Why Marketing Mix Modeling (MMM) is Crucial for Your Company" date="Oct 7, 2024"/>
        </div>
    );
};

export default Blog;